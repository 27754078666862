@font-face {
  font-family: "DejaVuSans";
  src: url("../public/fonts/Montserratarm-Medium.otf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "DejaVuSans";
  src: url("../public/fonts/Montserratarm-ExtraBold.otf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "DejaVuSans";
  src: url("../public/fonts/Montserratarm-Light.otf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../public/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: "Montserrat", "DejaVuSans";
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background: rgb(239 241 244);
}
.react-calendar__navigation__label__labelText {
  font-weight: 700;
}
.showNewMessages-hide {
  opacity: 0 !important;
  max-width: 0px !important;
  max-height: 0px !important;
}
.showNewMessages-visible {
  opacity: 1 !important;
  max-width: 200px !important;
  max-height: 40px !important;
}
